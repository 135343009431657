/**
* Template Name: Flattern - v2.2.0
* Template URL: https://bootstrapmade.com/flattern-multipurpose-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Rubik+Iso&display=swap');
body {
    font-family: "Poppins", sans-serif;
    color: #4d4643;
  }
  
  a {
    color: #004a79;
  }
  
  a:hover {
    color: #2395ff;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    right: 15px;
    bottom: 15px;
    background: #007dcb;
    color: #fff;
    transition: display 0.5s ease-in-out;
    z-index: 99999;
  }
  
  .back-to-top i {
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  
  .back-to-top:hover {
    color: #fff;
    background: #2395ff;
    transition: 0.3s ease-in-out;
  }
  
  /*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #f8f6f5;
    padding: 10px 0;
    font-size: 14px;
  }
  
  #topbar .contact-info a {
    line-height: 1;
    color: #4d4643;
    transition: 0.3s;
  }
  
  #topbar .contact-info a:hover {
    color: #007dcb;
  }
  
  #topbar .contact-info i {
    color: #007dcb;
    padding: 4px;
  }
  
  #topbar .contact-info .icofont-phone {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #e9e9e9;
  }
  
  #topbar .social-links a {
    color: #836a61;
    padding: 4px 0 4px 20px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  
  #topbar .social-links a:hover {
    color: #007dcb;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 10px 0;
    background: #fff;
    
    
  }
  
    #header.header-scrolled {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      
    }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 10px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #000000;
    text-decoration: none;
    font-family:  'Helvetica Neue', sans-serif;
    font-weight: bolder;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
    
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }
  
  .nav-menu > ul > li + li {
    padding-left: 20px;
  }
  
  .nav-menu a {
    display: block;
    position: relative;
    color: #6f84a2;
    padding: 14px 0 15px 0;
    transition: 0.3s;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: #007dcb;
    text-decoration: none;
  }
  
  .nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 20px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
  }
  
  .nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 500;
    text-transform: none;
    color: #001b73;
  }
  
  .nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #007dcb;
  }
  
  .nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
  }
  
  .nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
  }
  
  .nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
  }
  
  .nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
  }
  
  .nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
  }
  
  @media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
      left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
      left: -100%;
    }
    .nav-menu .drop-down .drop-down > a:after {
      content: "\ea9d";
    }
  }
  
  /* Mobile Navigation */
  .mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 20px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
  }
  
  .mobile-nav-toggle i {
    color: #65818fa8;
  }
  
  .mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
  }
  
  .mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .mobile-nav a {
    display: block;
    position: relative;
    color: #2b2320;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
    color: #007dcb;
    text-decoration: none;
  }
  
  .mobile-nav .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
  }
  
  .mobile-nav .active.drop-down > a:after {
    content: "\eaa1";
  }
  
  .mobile-nav .drop-down > a {
    padding-right: 35px;
  }
  
  .mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
  }
  
  .mobile-nav .drop-down li {
    padding-left: 20px;
  }
  
  .mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(14, 11, 10, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
  }
  
  .mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    position: relative;
    padding: 0;
  }
  
  #hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  #hero .carousel-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    top: 110px;
    left: 50px;
    right: 50px;
    
  }
  
  #hero .carousel-content {
    background: rgba(0, 0, 0, 0.252);
    padding: 20px;
    color: #fff;
    -webkit-animation-duration: .5s;
    animation-duration: 1s;
    border-top: 5px solid #007dcb;
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
  
  #hero .carousel-content h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 700;
  }
  
  #hero .btn-get-started {
    font-family: "Muli", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #007dcb;
  }
  
  #hero .btn-get-started:hover {
    background: #007dcb;
    color: #fff;
    text-decoration: none;
  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-left,
  #hero .carousel-inner .carousel-item-prev.carousel-item-right {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .carousel-indicators li {
    cursor: pointer;
  }
  
  @media (min-width: 1024px) {
    #hero .carousel-content {
      width: 60%;
    }
    #hero .carousel-control-prev, #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 992px) {
    #hero {
      height: calc(100vh - 70px);
    }
    #hero .carousel-content h2 {
      margin-bottom: 15px;
      font-size: 22px;
    }
    #hero .carousel-content p {
      font-size: 15px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f6f3f2;
  }
  
  .section-title {
    padding-bottom: 30px;
    text-align: center;
  }
  
  .section-title h2 {
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #65534c;
  }
  
  .section-title h2 strong {
    font-weight: 700;
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #2b2320;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
    color: #fff;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #ded5d2;
  }
  
  .breadcrumbs ol a {
    color: #fe825a;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #c8bab5;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: #f3f1f0;
    padding: 60px 0;
    color: #574741;
  }
  
  .cta h3 {
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta h3 span {
    color: #007dcb;
  }
  
  .cta p {
    padding: 0;
    margin: 0;
  }
  
  .cta .cta-btn {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 25px;
    border-radius: 2px;
    transition: 0.4s;
    margin: 10px;
    border-radius: 4px;
    border: 2px solid #007dcb;
    color: #007dcb;
    background: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #007dcb;
    color: #fff;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    padding: 50px 20px;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    border: 1px solid #ebe6e4;
    height: 200px;
    position: relative;
    background: #a4d6ff6f;
    border-radius: 10px;
  }
  
  .services .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 72px;
    height: 72px;
    background: #007dcb;
  }
  
  .services .icon i {
    color: #fff;
    font-size: 24px;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .services .title a {
    color: #343a40;
  }
  
  .services .icon-box:hover .icon {
    background: #fff;
    border: 2px solid #007dcb;
  }
  
  .services .icon-box:hover .icon i {
    color: #007dcb;
  }
  
  .services .icon-box:hover .title a {
    color: #007dcb;
  }
  
  .services .description {
    line-height: 24px;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 50px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: #4d4643;
    transition: all 0.3s;
    border-radius: 50px;
  }
  
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #007dcb;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #2b2320;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: #65534c;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #483b36;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #007dcb;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a:hover {
    color: #fe825a;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 20px;
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients .clients-wrap {
    border-top: 1px solid #f1eeed;
    border-left: 1px solid #f1eeed;
  }
  
  .clients .client-logo {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f1eeed;
    border-bottom: 1px solid #f1eeed;
    overflow: hidden;
    background: #fff;
    height: 100px;
  }
  
  .clients .client-logo img {
    transition: all 0.3s ease-in-out;
    height: 36px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  
  .clients .client-logo:hover img {
    -webkit-filter: none;
    filter: none;
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  .about-us .content {
    padding: 30px 0;
  }
  
  .about-us .content h3 {
    font-weight: 700;
    font-size: 34px;
  }
  
  .about-us .content p {
    margin-bottom: 0;
  }
  
  .about-us .content .icon-box {
    margin-top: 25px;
  }
  
  .about-us .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
  }
  
  .about-us .content .icon-box i {
    font-size: 48px;
    float: left;
    color: #007dcb;
  }
  
  .about-us .content .icon-box p {
    font-size: 15px;
    color: #90847f;
    margin-left: 60px;
  }
  
  .about-us .image {
    /* background: url("img/about.jpg") center center no-repeat; */
    background-size: cover;
    min-height: 400px;
  }
  
  @media (max-width: 991px) {
    .about-us .image {
      text-align: center;
    }
    .about-us .image img {
      max-width: 80%;
    }
  }
  
  @media (max-width: 667px) {
    .about-us .image img {
      max-width: 100%;
    }
  }
  
  /*--------------------------------------------------------------
  # Our Team
  --------------------------------------------------------------*/
  .team .member {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: #2b2320;
    margin: 0 10px;
    padding-top: 8px;
    display: inline-block;
  }
  
  .team .member .social a:hover {
    color: #007dcb;
  }
  
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  
  .team .member .member-info {
    padding: 25px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2b2320;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #b4aca8;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #847872;
  }
  
  .team .member:hover .social {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Our Skills
  --------------------------------------------------------------*/
  .skills .progress {
    height: 50px;
    display: block;
    background: none;
  }
  
  .skills .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #2b2320;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  
  .skills .progress-bar-wrap {
    background: #ded5d2;
  }
  
  .skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: #007dcb;
  }
  
  /*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features {
    padding-top: 0;
  }
  
  .features .nav-tabs {
    border: 0;
  }
  
  .features .nav-link {
    border: 0;
    padding: 25px 20px;
    transition: 0.3s;
    color: #2b2320;
  }
  
  .features .nav-link:hover {
    color: #007dcb;
  }
  
  .features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
  }
  
  .features .nav-link p {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .features .nav-link.active {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.12);
    color: #007dcb;
  }
  
  .features .tab-pane.active {
    -webkit-animation: slide-down 0.5s ease-out;
    animation: slide-down 0.5s ease-out;
  }
  
  @-webkit-keyframes slide-down {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slide-down {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 20px;
    background: #f3f1f0;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    padding: 15px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #2b2320;
  }
  
  .pricing h4 {
    font-size: 42px;
    color: #007dcb;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #2b2320;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  
  .pricing .btn-buy {
    color: #9c847b;
    border: 2px solid #e9e3e1;
    display: inline-block;
    padding: 10px 40px 12px 40px;
    border-radius: 4px;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    color: #007dcb;
    border-color: #007dcb;
  }
  
  .pricing .featured {
    background: #2395ff;
    box-shadow: none;
  }
  
  .pricing .featured h3, .pricing .featured h4, .pricing .featured h4 span, .pricing .featured ul, .pricing .featured ul .na {
    color: #fff;
  }
  
  .pricing .featured .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  
  .pricing .featured .btn-buy {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.5);
  }
  
  .pricing .featured .btn-buy:hover {
    color: #007dcb;
    background: #fff;
    border-color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Cta Pricing
  --------------------------------------------------------------*/
  .cta-pricing {
    background: #f3f1f0;
    color: #65534c;
  }
  
  .cta-pricing h3 {
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta-pricing .cta-btn {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 25px;
    border-radius: 2px;
    transition: 0.4s;
    margin: 10px;
    border-radius: 4px;
    border: 2px solid #007dcb;
    color: #007dcb;
    background: #fff;
  }
  
  .cta-pricing .cta-btn:hover {
    background: #007dcb;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #fecfc0;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-wrap {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .contact .info {
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #007dcb;
    float: left;
    width: 44px;
    height: 44px;
    background: #ffeee8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2b2320;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #65534c;
  }
  
  .contact .info:hover i {
    background: #007dcb;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #007dcb;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #2395ff;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding: 40px;
  }
  
  .portfolio-details .portfolio-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  
  .portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: center;
  }
  
  .portfolio-details .portfolio-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
  }
  
  .portfolio-details .portfolio-details-carousel .owl-dot.active {
    background-color: #007dcb !important;
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-info p {
    font-size: 15px;
    padding: 15px 0 0 0;
  }
  
  @media (max-width: 992px) {
    .portfolio-details .portfolio-info {
      padding-top: 20px;
    }
  }
  
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog {
    padding: 40px 0 20px 0;
  }
  
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    background: #f8f6f5;
  }
  
  .blog .entry .entry-img {
    max-height: 400px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #2b2320;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #007dcb;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #9c847b;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 14px;
    padding-right: 4px;
  }
  
  .blog .entry .entry-meta a {
    color: #836a61;
    font-size: 14px;
    display: inline-block;
    line-height: 20px;
  }
  
  .blog .entry .entry-content p {
    line-height: 24px;
  }
  
  .blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #007dcb;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #fd541e;
  }
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #4d4643;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote .quote-left {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 36px;
    color: #e7e7e7;
  }
  
  .blog .entry .entry-content blockquote .quote-right {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 36px;
    color: #e7e7e7;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #2b2320;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #836a61;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #ae9a92;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #007dcb;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
  }
  
  .blog .blog-author h4 {
    margin-left: 140px;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
  }
  
  .blog .blog-author .social-links {
    margin: 0 0 5px 140px;
  }
  
  .blog .blog-author .social-links a {
    color: #9c847b;
  }
  
  .blog .blog-author p {
    margin-left: 140px;
    font-style: italic;
    color: #bfb9b6;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    width: 50px;
  }
  
  .blog .blog-comments .comment h5 {
    margin-left: 65px;
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #4d4643;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #007dcb;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #2b2320;
  }
  
  .blog .blog-comments .comment time {
    margin-left: 65px;
    display: block;
    font-size: 14px;
    color: #9c847b;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment p {
    margin-left: 65px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 0;
    padding: 20px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #fe825a;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 0;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #fe825a;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    padding: 8px 20px;
    border: 0;
    border-radius: 4px;
    background-color: #2b2320;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #007dcb;
  }
  
  .blog .blog-pagination {
    color: #65534c;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    border: 1px solid white;
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li.active {
    background: white;
  }
  
  .blog .blog-pagination li a {
    color: #b4aca8;
    padding: 7px 16px;
    display: inline-block;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #007dcb;
    border: 1px solid #007dcb;
    border-radius: 4px;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  
  .blog .blog-pagination li.disabled {
    background: #fff;
    border: 1px solid white;
  }
  
  .blog .blog-pagination li.disabled i {
    color: #f6f5f5;
    padding: 10px 16px;
    display: inline-block;
  }
  
  .blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    background: #f8f6f5;
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #2b2320;
    position: relative;
  }
  
  .blog .sidebar .sidebar-item {
    margin-bottom: 30px;
  }
  
  .blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
    border-radius: 4px;
  }
  
  .blog .sidebar .search-form form input[type="text"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 40px);
  }
  
  .blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #007dcb;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  .blog .sidebar .search-form form button:hover {
    background: #2395ff;
  }
  
  .blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .categories ul li + li {
    padding-top: 10px;
  }
  
  .blog .sidebar .categories ul a {
    color: #745f56;
  }
  
  .blog .sidebar .categories ul a:hover {
    color: #007dcb;
  }
  
  .blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #9c847b;
    font-size: 14px;
  }
  
  .blog .sidebar .recent-posts .post-item + .post-item {
    margin-top: 15px;
  }
  
  .blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
  }
  
  .blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
  }
  
  .blog .sidebar .recent-posts h4 a {
    color: black;
    transition: 0.3s;
  }
  
  .blog .sidebar .recent-posts h4 a:hover {
    color: #007dcb;
  }
  
  .blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #9c847b;
  }
  
  .blog .sidebar .tags {
    margin-bottom: -10px;
  }
  
  .blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .tags ul li {
    display: inline-block;
  }
  
  .blog .sidebar .tags ul a {
    color: #745f56;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #ded5d2;
    display: inline-block;
    transition: 0.3s;
    border-radius: 4px;
  }
  
  .blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #007dcb;
    background: #007dcb;
  }
  
  .blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #c8bab5;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #b4aca8;
    font-size: 14px;
    background: #14100f;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: black;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 22px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Muli", sans-serif;
    color: #b4aca8;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #fd6f41;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #b4aca8;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #fd6f41;
  }
  
  #footer .footer-newsletter {
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px 0 0 4px;
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #007dcb;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #2395ff;
  }
  
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #b4aca8;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #007dcb;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #2395ff;
    color: #fff;
    text-decoration: none;
  }
  

#text {
  font-size: 200px;
  font-family: 'Rubik Iso', sans-serif; 
  font-weight: bold;
  color: rgb(0, 94, 255);
  align-content: center;
  text-align: center; 
  animation: stamp 2s infinite;
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.4));
}

@keyframes stamp {
  0%, 20% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  
  40% {
    transform: translateY(-20px) scale(0.8);
    opacity: 0.8;
  }
  
  60% {
    transform: translateY(-10px) scale(1.2);
    opacity: 0.4; 
  }
  
  80% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}

#loader {
  height: 5px;
  width: 0%;
  background-color: #007bff;
  
  position: relative;
  overflow: hidden;
  
  animation: expand 2s ease-out;
}

@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.card{
  max-width: 40rem;
  justify-content: center;
  align-items: center;
  margin-left: 22%;
  
}
.card-img-top {
  border-radius: 30px;
}

.courses{
  max-width: 100rem;
}
.green h2 {
  color: #009345;
}
.green h3{
  color: #009345;
}